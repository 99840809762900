img.logo{
  max-height: 150px;
}

.header-blue{
  color: #035ea3;
  font-weight: 600;
  line-height: 40px;
}

.header-lightBlue{
  color: #1493c9;
  font-weight: 400;
  line-height: 40px;
  font-style: normal;
}

.ministry-text {
  font-size: 18px;
  font-weight: 400;
}

h3{
  padding-top: 20px;
}

.header-red{
  color: #b52b2b;
  font-weight: 600;
  line-height: 40px;
}

.red-text{
  color: #b52b2b;
}

.red-text-bold{
  color: #b52b2b;
  font-weight: bold;
}

.lightBlue-text-bold{
  color: #1493c9;
  font-weight: bold;
}


.sermon-search-button {
  padding: 2px 35px !important;
  border-color: #65AAD5;
  color: #65AAD5;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  border-color: #65AAD5 !important;
  background-color: #65AAD5 !important;
}

.btn-primary{
  border-color: #65AAD5;
}

.bg-color-theme {
  background-color: #65AAD5;
}

.latestSermon-img {
    width: 100% !important;
    height: 100% !important;
    /*stretches image to fill space*/
  }

  .contact-column {
    background-color: #ededed;
    padding: 20px;
  }

  .contact-header {
    margin-top: 33px !important;
  }

.text-center {
  text-align: center;
}

.image-center {
  display: block;
    margin-left: auto;
    margin-right: auto;
}
.person-name{
  font-size: 24px;
margin-bottom: 8px;
margin-top: 10px;
font-weight: 300;
line-height: 24px;
}

.person-title{
  font-size: 16px;
  color: #a2a5a4;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.no-bottom-margin{
  margin-bottom: 0;
}

.no-top-margin{
  margin-top: 0;
  padding-top: 0;
}

.directions-div{
  margin-top: 5px;
  background-color: #10749e;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  color: #d3d3d3;
  max-width: 400px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/*Fix for overflowing text on gmaps*/
.gm-style-iw, .gm-style-iw>div {
  width: auto !important;
  overflow: hidden !important;
  min-height: 205px;
 }

 /* Size of images on our people*/
 .person-image>img {
   width: auto;
   height: 160px;
 }

 .t2Women-img{
width: auto;
height: 200px;
 }

 .quarry-logo{
  width: auto;
  height: 300px;
   }

   .btn-link{
    color: #1493c9;
   }

   .sermon-page-image, .sermon-series-page-image{
     max-height: 400px !important;
   }

   .no-list-style{
     list-style: none;
   }

   @media (max-width: 767px) {
    .nav-collapse{
      margin-top: -55px !important;
    }

    /*Stop mobile menu from flashing when losing focus*/
    .tb-megamenu .nav-collapse .nav > li > a:hover,
.tb-megamenu .nav-collapse .nav > li > a:focus,
.tb-megamenu .nav-collapse .dropdown-menu a:hover,
.tb-megamenu .nav-collapse .dropdown-menu a:focus {
    background-color: #000;
  }

  .tb-megamenu .nav li.dropdown.open > .dropdown-toggle {
    background-color: #000;
    color: #65aad5 !important;
  }
  .nav .open > a, .nav .open > a:hover, .nav.open > a:focus{
    background-color: #000;
  }
}

/*Systematic theology page*/
.play-btn{
  max-height: 35px !important;
  padding-left: 10px;
  padding-right: 5px;
}

.notes-btn{
  max-height: 35px !important;
  padding-left: 10px;
  padding-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.systematic-theology-resources {
  border-collapse: collapse;
  border: 2px solid black;
}

.no-left-right-border{
  border-left: 0px solid !important;
  border-right: 0px solid !important;
}

.unit-title{
  color: #1493c9;
  font-weight: 400;
  line-height: 40px;
font-style: normal;
font-size: 20px;
}

@media (min-width: 991px) {
  .hideForPC{
    display: none; 
  }
}

.street-view-container{
  margin-top: 50px;
  display: inline-block;
  max-width: 99%;
}

.course-item-margin{
  margin-left: 100px;
}

@media (max-width: 685px)
{
  .course-item-margin
  {
    margin-left: 0px;
  }
}